







































































































































import {
	ApplicationUserModel,
	PermissionTypeEnum,
	PlanItemModel,
	PredicateDefinition,
	TenantUserModel
} from '@/libs/Api';
import {CrudAction, CrudReponse, CrudResponseAction} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import Page from '@/Page.vue';
import moment from 'moment';
import {Component, Prop, PropSync, Watch} from 'vue-property-decorator';
import {planItemsStore} from '@/libs/plan-items/+state/store';
import {applicationUserStore} from '@/libs/user-settings/+state/store';
import {CrudQueryPaging} from '@/libs/core/+state/models/crud-query-paging';
import InfiniteLoading from 'vue-infinite-loading';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import {PredicateBuilder} from '@/libs/core/functions/predicate-builder';
import {workTasksShowDetailsStore, WorkTasksShowDetailTypeEnum} from '@/libs/work-tasks/+state/store';

@Component({
    components: {
        InfiniteLoading
    },
	computed: {
		WorkTasksShowDetailTypeEnum() {
			return WorkTasksShowDetailTypeEnum;
		}
	}
})
export default class WorkTaskKanbanColumn extends Page {

    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail);
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    pageIndex = 0;
    items: PlanItemModel[] = [];
    loadDoneTodayWorkTaskCountTimeout: any = null;
    subscribtion: any = null;
    infiniteId = +new Date();

    @Prop() date!: Date;
    @Prop() to!: any;
    @Prop() onlyMine!: boolean;
    @Prop() department!: Array<string> | null;
    @Prop() position!: Array<string> | null;
    @Prop() filterUser!: Array<string> | null;
    @Prop() filterCategory!: Array<string> | null;
    @PropSync("rowCount") columnRowCount!: number;

    get waitingItems() : PlanItemModel[] {
        return this.items.filter(x => x.isAnswered == false);
    }

    get doneItems() : PlanItemModel[] {
        return this.items.filter(x => x.isAnswered == true);
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic, "WaitingItems-" + moment(this.date).format("YYYY-MM-DD")) || planItemsStore.useGetter(CrudGetter.PendingBasic, "DoneItems-" + moment(this.date).format("YYYY-MM-DD"));
    }

    get doneTodayWorkTaskCount(): number {
        // return workTasksStore.useGetter(CrudGetter.Count, "DoneTodayWorkTaskCount" + moment(this.date).format("YYYY-MM-DD"));
        return 0;
    }

    get canPerformAudit() {
        const permissions = authStore.useGetter(AuthGetter.GetPermissions) as PermissionTypeEnum[];
        return permissions.contains(PermissionTypeEnum.PerformAudit);
    }

    @Watch("onlyMine")
    @Watch("department")
    @Watch("position")
    @Watch("filterUser")
    @Watch("filterCategory")
    filterChanged () {
        this.items = [];
        this.infiniteId = +new Date();
    }

    mounted () {
        const self = this;
        this.subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === planItemsStore.getActionName(CrudResponseAction.PartialUpdateSuccess)) {
                self.items.forEach((x) => {
                    if (x.id == mutation.payload?.item?.id) {
                        self.items.splice(self.items.indexOf(x), 1, mutation.payload!.item);
                    }
                    if (x.id == mutation.payload?.itemBasic?.id) {
                        self.items.splice(self.items.indexOf(x), 1, mutation.payload!.itemBasic);
                    }
                });
            }
            else if (mutation.type === planItemsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                self.items.forEach((x) => {
                    if (x.id == mutation.payload?.item?.id) {
                        self.items.splice(self.items.indexOf(x), 1, mutation.payload!.item);
                    }
                    if (x.id == mutation.payload?.itemBasic?.id) {
                        self.items.splice(self.items.indexOf(x), 1, mutation.payload!.itemBasic);
                    }
                });
            }
			else if (mutation.type === planItemsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
				self.items.forEach((x) => {
					mutation.payload.data.forEach((n: PlanItemModel) => {
						if (x.id == n?.id) {
							self.items.splice(self.items.indexOf(x), 1);
						}
					});
				});
			}
			else if (mutation.type === planItemsStore.getActionName(CrudResponseAction.CreateSuccess)) {
				if (mutation.payload) {
					const expression = PredicateBuilder.GetExpression(this.getPredicates() as PredicateDefinition[]);
					const result = expression.call(this, mutation.payload.item);
					if (result) {
						self.refresh();
					}
				}
			}
        })
    }

    destroyed () {
        if (typeof this.subscribtion == "function")
            this.subscribtion();
    }

	private getPredicates(): CrudQueryPredicate[] {
		const filterPredicates = [
			{ field: "time", op: "gte", comparand: moment(this.date).startOf("day").toDate() },
			{ field: "time", op: "lte", comparand: moment(this.date).endOf("day").toDate() }
		] as any;
		let userFilters: CrudQueryPredicate[] = [];
		let departmentFilters: CrudQueryPredicate[] = [];
		let positionFilters: CrudQueryPredicate[] = [];
		if (this.filterCategory && this.filterCategory.length > 0) {
			filterPredicates.push({
				field: "audit.categoryId",
				op: "in",
				comparand: this.filterCategory
			});
		}
		if (this.onlyMine) {
			filterPredicates.push({
				field: "tenantUserId", op: "eq", comparand: this.tenantUser.id
			});
		}
		else {
			if (this.filterUser && this.filterUser.length > 0) {
				this.filterUser.forEach((e: string) => {
					if (e) {
						userFilters.push({
							field: "tenantUserId",
							op: "eq",
							comparand: e,
							junction: "or"
						});
					}
				});
			}
			if (this.department && this.department.length > 0) {
				this.department.forEach((e: string) => {
					if (e) {
						departmentFilters.push({
							field: "tenantUser.departmentId",
							op: "eq",
							comparand: e,
							junction: "or"
						});
					}
				});
			}
			if (this.position && this.position.length > 0) {
				this.position.forEach((e: string) => {
					if (e) {
						positionFilters.push({
							field: "tenantUser.positionId",
							op: "eq",
							comparand: e,
							junction: "or"
						});
					}
				});
			}
			if (userFilters.length > 0) {
				filterPredicates.push({
					field: "tenantUserId",
					op: "wrap",
					comparand: userFilters
				});
			}
			if (departmentFilters.length > 0) {
				filterPredicates.push({
					field: "tenantUser.departmentId",
					op: "wrap",
					comparand: departmentFilters
				});
			}
			if (positionFilters.length > 0) {
				filterPredicates.push({
					field: "tenantUser.positionId",
					op: "wrap",
					comparand: positionFilters
				});
			}
		}
		return filterPredicates;
	}

    private loadItems ($state : any = null) {
        const key = "Items-" + moment(this.date).format("YYYY-MM-DD");
        planItemsStore.dispatch(CrudAction.GetAllBasic, createCrudQueryPayload<PlanItemModel>([
            { field: "isAnswered", index: 1, order: "asc" },
            { field: "createdAt", index: 2, order: "asc" }
        ], this.getPredicates(), {
            pageIndex: Math.floor(this.items.length / 20) + 1,
            pageSize: 20
        } as CrudQueryPaging, false, key));
        this.subscribe(planItemsStore, CrudReponse.GetAllBasic, key).then((e: PlanItemModel[]) => {
            var loadedNewItems = e.filter(x => !this.items.any(p => x.id == p.id)).length > 0;
            this.items = [
                ...this.items.filter(x => !e.any(p => x.id == p.id)),
                ...e
            ];
            const dataStats = planItemsStore.useGetter(CrudGetter.DataStatsBasic, key);
            if ($state) {
                if (this.items.length < dataStats.rowCount && loadedNewItems)
                    $state.loaded();
                else
                    $state.complete();
            }
        }).catch(() => {
            if ($state)
                $state.complete();
        });
    }

    private open (item: PlanItemModel) {
        this.$emit("open", item);
    }
    
    deletePlanItemClick(item: PlanItemModel) {
        this.$emit("deletePlanItemClick", item);
    }

	public refresh() {
		this.items = [];
		this.infiniteId = +new Date();
	}

	getShowInfo(code: WorkTasksShowDetailTypeEnum): boolean {
		return workTasksShowDetailsStore.useGetter(code);
	}

}
