






















































































import {ApplicationUserModel, AuditModel, PlanItemModel, TenantUserModel} from '@/libs/Api';
import Page from '@/Page.vue';
import { Guid } from '@/libs/common/functions/guid';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import moment from 'moment';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import ShiftTypeSelect from '@/libs/common/components-smart/ShiftTypeSelect.vue';
import {auditsStore} from '@/libs/audits/+state/store';
import CustomerSelect from '@/libs/common/components-smart/CustomerSelect.vue';

@Component({
	components: {CustomerSelect, ShiftTypeSelect}
})
export default class WorkTaskCreateDialog extends Page {

    dialog = false;
    valid = true;
    saving = false;
	isPeriod = false;
    item: PlanItemModel | null = null;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
	audit: AuditModel | null = null;
    
    @Prop({ default: false }) activator!: boolean;
    @Prop({ default: false }) mobile!: boolean;

    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Ref() form!: any;
    @Prop() tenantId!: string;
    @Prop() auditId!: string;

    open () {
        this.$nextTick(() => {
			if (this.auditId) {
				auditsStore.dispatch(CrudAction.Get, { id: this.auditId });
				this.subscribe(auditsStore, CrudReponse.Get).then((e: AuditModel) => {
					this.audit = e;
					this.dialog = true;
					this.isPeriod = false;
					this.item = {
						id: Guid.EmptyGuid(),
						auditId: this.auditId,
						audit: e,
						tenantUserId: this.tenantUser.id,
						time: moment().add(1, "day").format("YYYY-MM-DDT08:00:00"),
						workplaceId: null
					} as PlanItemModel;
				});
			}
			else {
				this.dialog = true;
				this.isPeriod = false;
				this.item = {
					id: Guid.EmptyGuid(),
					auditId: undefined,
					audit: undefined,
					tenantUserId: this.tenantUser.id,
					time: moment().add(1, "day").format("YYYY-MM-DDT08:00:00"),
					workplaceId: null
				} as PlanItemModel;
			}

        });
    }

	onAuditChange(auditId: string) {
		auditsStore.dispatch(CrudAction.Get, { id: auditId });
		this.subscribe(auditsStore, CrudReponse.Get).then((e: AuditModel) => {
			this.audit = e;
			this.item!.auditId = auditId;
			this.item!.audit = e;
		});
	}

    close () {
        this.dialog = false;
		this.audit = null;
    }

    save () {
        if (this.form.validate()) {
            this.saving = true;
            const item = {...this.item};
            const time = moment(item.time).format("YYYY-MM-DDT08:00:00");
	        item.since = time;
			if (!this.isPeriod) {
				item.until = time;
			}
            planItemsStore.dispatch(CrudAction.Create, { item: item });
            this.subscribe(planItemsStore, CrudReponse.Create).then((e: PlanItemModel) => {
                this.saving = false;
				this.$emit("created", e);
                this.close();
            }).catch((e: any) => {
                this.saving = false;
            });
        }
    }

}
