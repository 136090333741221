




















































































































































































import { PlanItemBasicModel, PlanItemModel, ResponseModel, TenantUserModel } from '@/libs/Api';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import Page from '@/Page.vue';
import { Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { unansweredStore } from '../+state/store';
import moment from 'moment';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

@Component({
    components: {
    }
})
export default class Unanswered extends Page {

    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    filterCategory: Array<string> | null = null;
    filterAudit: Array<string> | null = null;
    filterUser: Array<string> | null = null;
    filterDepartment: Array<string> | null = null;
    filterPosition: Array<string> | null = null;
    filterWorkplace: Array<string> | null = null;
    filterGroup: Array<string> | null = null;
    filterState = "all";
    filterCorrectiveMeasure = 0;
    filterIsImplementationClosed: any = null;
    filterResponseValues: string[] = [];
    filterSince: string | null = null;
    filterTill: string | null = null;
    mobileFiltersShow = false;
    escalation = 0;
    pageIndex = 1;
    pageSize = 25;
    pagingChanged = false;
    
    get headers() {
        return [
            { text: this.$i18n.t("planItems.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.audit.name"), value: "audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.category.name"), value: "planItems.category.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.user.displayName"), value: "tenantUser.user.displayName", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.userGroup"), value: "", align: "left", sortable: false }, // Skupina uživatelů
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }

    get totalRows(): number {
        const stats = planItemsStore.useGetter(CrudGetter.DataStatsBasic) as CrudDataStats;
        return stats?.rowCount ?? 0;
    }

    @Prop() tenantId!: string;
    
    @Watch("filterState")
    filterStateChanged () {
        if (this.filterState == "withoutNok") {
            this.escalation = 0;
            if (this.filterCorrectiveMeasure != 0) {
                this.escalation++;
                this.filterCorrectiveMeasure = 0;
            }
            if (this.filterIsImplementationClosed != null) {
                this.escalation++;
                this.filterIsImplementationClosed = null;
            }
        }
        this.load();
    }

    get responseValueItems() {
        return [
            { text: this.$i18n.t("responseValue.ok"), value: "ok" },
            { text: this.$i18n.t("responseValue.okWithWarning"), value: "okWithWarning" },
            { text: this.$i18n.t("responseValue.notOk"), value: "notOk" },
            { text: this.$i18n.t("responseValue.scoreZero"), value: "scoreZero" },
            { text: this.$i18n.t("responseValue.scoreOne"), value: "scoreOne" },
            { text: this.$i18n.t("responseValue.scoreTwo"), value: "scoreTwo" },
            { text: this.$i18n.t("responseValue.scoreThree"), value: "scoreThree" },
            { text: this.$i18n.t("responseValue.scoreFour"), value: "scoreFour" },
            { text: this.$i18n.t("responseValue.scoreFive"), value: "scoreFive" },
        ];
    }

    @Watch("filterCategory")
    @Watch("filterAudit")
    @Watch("filterUser")
    @Watch("filterDepartment")
    @Watch("filterPosition")
    @Watch("filterWorkplace")
    @Watch("filterGroup")
    @Watch("filterCorrectiveMeasure")
    @Watch("filterIsImplementationClosed")
    @Watch("filterResponseValues")
    @Watch("filterSince")
    @Watch("filterTill")
    filtersChanged () {
        this.pageIndex = 1;
        this.escalation--;
        if (this.escalation <= 0)
            this.load();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged () {
        this.saveQuery();
        if (this.pagingChanged) {
            this.pagingChanged = false;
            return;
        }
        this.load();
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic);
    }
    
    get planItems (): PlanItemModel[] {
        return [...planItemsStore.useGetter(CrudGetter.DataBasic)];
    }

    get downloading(): boolean {
        return unansweredStore.useGetter(CrudGetter.Downloading);
    }

    // get totalRows (): number {
    //     return ({...planItemsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    // }

    mounted () {
        if (this.getQueryInt("pageSize") != null) {
            this.pageSize = this.getQueryInt("pageSize") ?? 25;
        }
        else {
            if (window.localStorage.ptPageSize && Number(window.localStorage.ptPageSize)) {
                this.pageSize = Number(window.localStorage.ptPageSize);
            }
            this.saveQuery();
        }

        this.load();
    }

    load () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, this.getPayload());
    }

    getFilters(): CrudQueryPredicate[] {
        let filters: CrudQueryPredicate[] = [];
        let auditFilters: CrudQueryPredicate[] = [];
        let userFilters: CrudQueryPredicate[] = [];
        let departmentFilters: CrudQueryPredicate[] = [];
        let positionFilters: CrudQueryPredicate[] = [];
        let workplaceFilters: CrudQueryPredicate[] = [];
        let categoryFilters: CrudQueryPredicate[] = [];
        let groupFilters: CrudQueryPredicate[] = [];
        if (!this.tenantUser.accessAllCategories) {
            filters.push({ field: "audit.category.categoryUsers.tenantUserId", op: "eq", comparand: this.tenantUser.id! });
        }
        filters.push(
            { field: "isAnswered", op: "eq", comparand: false },
            { field: "time", op: "lte", comparand: moment().add(-1, "days").endOf("day").toDate() }
        );
        if (this.filterCategory && this.filterCategory.length > 0) {
            this.filterCategory.forEach((e: string) => {
                if (e) {
                    categoryFilters.push({
                        field: "audit.categoryId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterAudit && this.filterAudit.length > 0) {
            this.filterAudit.forEach((e: string) => {
                if (e) {
                    auditFilters.push({
                        field: "auditId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterUser && this.filterUser.length > 0) {
            this.filterUser.forEach((e: string) => {
                if (e) {
                    userFilters.push({
                        field: "tenantUserId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterDepartment && this.filterDepartment.length > 0) {
            this.filterDepartment.forEach((e: string) => {
                if (e) {
                    departmentFilters.push({
                        field: "tenantUser.departmentId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterPosition && this.filterPosition.length > 0) {
            this.filterPosition.forEach((e: string) => {
                if (e) {
                    positionFilters.push({
                        field: "tenantUser.positionId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterWorkplace && this.filterWorkplace.length > 0) {
            this.filterWorkplace.forEach((e: string) => {
                if (e) {
                    workplaceFilters.push({
                        field: "workplaceId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterGroup && this.filterGroup.length > 0) {
            this.filterGroup.forEach((e: string) => {
                if (e) {
                    groupFilters.push({
                        field: "auditTiming.groupId",
                        op: "eq",
                        comparand: e,
                        junction: "or"
                    });
                }
            });
        }
        if (this.filterState == "withNok") {
            filters.push({ field: "disagreementCount", op: "gt", comparand: 0 });
        }
        else if (this.filterState == "withoutNok") {
            filters.push({ field: "disagreementCount", op: "eq", comparand: 0 });
        }
        if (this.filterCorrectiveMeasure == 1) {
            filters.push({ field: "disagreementCount", op: "gt", comparand: 0 });
            filters.push({ field: "hasCorrection", op: "eq", comparand: true });
        }
        else if (this.filterCorrectiveMeasure == 2) {
            filters.push({ field: "disagreementCount", op: "gt", comparand: 0 });
            filters.push({ field: "hasCorrection", op: "eq", comparand: false });
        }
        if (this.filterIsImplementationClosed == true) {
            filters.push({ field: "isImplementationClosed", op: "eq", comparand: true });
        }
        else if (this.filterIsImplementationClosed == false) {
            filters.push({ field: "isImplementationClosed", op: "eq", comparand: false });
        }
        if (this.filterResponseValues.length > 0) {
            const filterResponseValues = {
                field: "id", op: "wrap", comparand: [] as any[]
            };
            for (const filterResponseValue of this.filterResponseValues) {
                let column = "";
                switch (filterResponseValue) {
                    case "ok":
                        column = "OkCount";
                        break;
                    case "okWithWarning":
                        column = "OkWarningCount";
                        break;
                    case "notOk":
                        column = "NokCount";
                        break;
                    case "scoreZero":
                        column = "ScoreZeroCount";
                        break;
                    case "scoreOne":
                        column = "ScoreOneCount";
                        break;
                    case "scoreTwo":
                        column = "ScoreTwoCount";
                        break;
                    case "scoreThree":
                        column = "ScoreThreeCount";
                        break;
                    case "scoreFour":
                        column = "ScoreFourCount";
                        break;
                    case "scoreFive":
                        column = "ScoreFiveCount";
                        break;
                }
                filterResponseValues.comparand.push({ field: column, op: "gt", comparand: 0, junction: "or" });
            }
            filters.push(filterResponseValues);
        }
        if (auditFilters.length > 0) {
            filters.push({
                field: "auditId",
                op: "wrap",
                comparand: auditFilters
            });
        }
        if (categoryFilters.length > 0) {
            filters.push({
                field: "audit.categoryId",
                op: "wrap",
                comparand: categoryFilters
            });
        }
        if (userFilters.length > 0) {
            filters.push({
                field: "tenantUserId",
                op: "wrap",
                comparand: userFilters
            });
        }
        if (departmentFilters.length > 0) {
            filters.push({
                field: "tenantUser.departmentId",
                op: "wrap",
                comparand: departmentFilters
            });
        }
        if (positionFilters.length > 0) {
            filters.push({
                field: "tenantUser.positionId",
                op: "wrap",
                comparand: positionFilters
            });
        }
        if (workplaceFilters.length > 0) {
            filters.push({
                field: "workplaceId",
                op: "wrap",
                comparand: workplaceFilters
            });
        }
        if (groupFilters.length > 0) {
            filters.push({
                field: "auditTiming.groupId",
                op: "wrap",
                comparand: groupFilters
            });
        }
        return filters;
    }

    getPayload() {
        return createCrudQueryPayload<PlanItemBasicModel>({ field: "time", index: 1, order: "desc" }, this.getFilters(), {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
        });
    }

    exportClick () {
        const filters = this.getFilters();
        unansweredStore.dispatch(CrudAction.Download, createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, filters));
    }

    saveQuery () {
        this.setQuery("pageSize", this.pageSize?.toString());
        window.localStorage.ptPageSize = this.pageSize?.toString();
    }

}
