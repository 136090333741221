import {
    HttpResponse,
    CustomerModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class CustomersDataService implements CrudService<CustomerModel, CustomerModel> {
    getAll(query: CrudDataQuery<CustomerModel>): Promise<CrudEnumerableResponse<CustomerModel>> {
        return ApiService.api.getCustomerBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    getBasicAll(query: CrudDataQuery<CustomerModel>): Promise<CrudEnumerableResponse<CustomerModel>> {
        throw new Error('NotImplemented');
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<CustomerModel>> {
        return ApiService.api.getCustomerById(id, params);
    }

    create(item: CustomerModel, params?: RequestParams): Promise<CrudResponse<CustomerModel>> {
        return ApiService.api.insertCustomer(item, params);
    }

    update(item: CustomerModel, params?: RequestParams): Promise<CustomerModel> {
        return ApiService.api.updateCustomer(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<CustomerModel> {
        return ApiService.api.patchCustomer(id, patches, params).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<CustomerModel>): Promise<CrudEnumerableResponse<CustomerModel>> {
        return ApiService.api.deleteCustomers(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        return ApiService.api.aggregationCustomer(query, params).then((req: any) => req.data.returnValue);
    }
    
    download(query: CrudDataQuery<CustomerModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw new Error('NotImplemented');
    }

    clone(item: CustomerModel, params?: RequestParams): Promise<CrudResponse<CustomerModel>> {
        throw new Error('NotImplemented');
    }

    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        return ApiService.api.getCustomerGroupped(query, params);
    }

}
