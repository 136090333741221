









































import { AuditModel, CategoryModel, TenantUserModel } from '@/libs/Api';
import { auditsStore } from '@/libs/audits/+state/store';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import WorkTaskCreateDialog from '@/libs/work-tasks/components/WorkTaskCreateDialog.vue'
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import { categoriesStore } from '@/libs/categories/+state/store';

@Component({
    components: {
        WorkTaskCreateDialog
    }
})
export default class Audit extends Page {

    loading = true;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
	category: CategoryModel | null = null;

    @Prop() tenantId!: string;
    @Prop() auditId!: string;

    get audit (): AuditModel {
        return auditsStore.useGetter(CrudGetter.Detail);
    }

    mounted () {
        this.loadAudit();
    }

    @Watch("auditId")
    auditIdChanged () {
        this.loadAudit();
    }

    loadAudit () {
        this.loading = true;
        auditsStore.dispatch(CrudAction.Get, { id: this.auditId });
        this.subscribe(auditsStore, CrudReponse.Get).then((e: AuditModel) => {
            this.loading = false;
            categoriesStore.dispatch(CrudAction.Get, { id: e.categoryId, key: "LoadAudit" });
            this.subscribe(categoriesStore, CrudReponse.Get, "LoadAudit").then((category: CategoryModel) => {
				this.category = category;
                if (!this.tenantUser.accessAllCategories && !category.categoryUsers!.any(x => x.tenantUserId == this.tenantUser.id)) {
                    this.$router.push({ name: 'Audits' });
                }
            })
        });
    }

}
