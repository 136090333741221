














































































































































































































import ApiService from "@/libs/core/api-service"
import {
    ApplicationUserModel,
    AvatarUploadModel,
    SetPasswordModel,
    TenantInvitationModel,
    TenantModel
} from '@/libs/Api';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {applicationUserStore, tenantConnectionsStore} from '../+state/store';
import {tenantsStore} from "@/libs/tenants/+state/store";
import {createCrudQueryPayload} from "@/libs/core/+state/models/crud-query-payload";
import {GlobalVariables} from '@/libs/core/global';
import TwoFactorDialog from '../components/TwoFactorDialog.vue';
import {authStore} from "@/libs/auth/+store/store";
import {AuthGetter} from "@/libs/auth/models/auth-state";

@Component({
    components: {
        TwoFactorDialog
    }
})
export default class UserSettings extends Page {

    uploadedFiles: any[] = [];
    uploadError: any = null
    currentStatus: any = null;
    uploadFieldName = 'photos';
    validPasswordChange = true;
    invalidPasswordAgain = false;
    errorPasswordChange = false;
    savedPasswordChange = false;
    savingPasswordChange = false;
    oldPassword = "";
    password = "";
    passwordAgain = "";
    twoFactorDialogType = "";
    basicRules = [
        (v: any) => (!!v) || this.$i18n.t('common.thisFileIsRequired')
    ];
    valid = true;
    acceptingInvitation: string | null = null;
    deletingInvitation: string | null = null;
    deletingOrganization = false;
    deleteOrganizationDialog = false;
    deleteOrganizationItem: TenantModel | null = null;
    locale = "cs";
    itemsPerPage = 10;
    tenantDialog = false;
    twoFactorDialog = false;
    twoFactorValue = '';
    
    @Prop() tenantId!: string;
    @Ref() uploadAvatarInput!: any;
    @Ref() form!: any;
    @Ref() formPasswordChange!: any;
    
    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    get isSaving(): boolean {
        return applicationUserStore.useGetter(CrudGetter.Saving);
    }

    get user(): ApplicationUserModel {
        return {...applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel};
    }

    get invitations(): TenantInvitationModel[] {
        return tenantConnectionsStore.useGetter(CrudGetter.Data);
    }

    get organizations(): TenantModel[] {
        return tenantsStore.useGetter(CrudGetter.Data);
    }

    get headers() {
        return [
            { text: this.$i18n.t("common.name"), value: "name", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false },
        ];
    }
    
    get isSingleTenant(): boolean {
        return authStore.useGetter(AuthGetter.GetIsSingleTenant) ?? false;
    }

    created () {
        this.$i18n.locale = window.localStorage.language ?? "cs";
        if (!window.localStorage.language) {
        window.localStorage.language = "cs";
        }
        this.locale = this.$i18n.locale;
        this.$vuetify.lang.current = this.locale;
        GlobalVariables.lang = this.locale;
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.loadInvitations();
        const isSingleTenant = authStore.useGetter(AuthGetter.GetIsSingleTenant) ?? false;
        if (this.organizations.length == 0 && !isSingleTenant)
            this.tenantDialog = true;
    }

    getFileBase64(file: File, callback: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result!.toString().split(',')[1]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    filesChange (fieldName: string, fileList: FileList) {

        if (!fileList.length) return;

        this.getFileBase64(fileList[0], (e: string) => {

            const uploadModel = {
                contentType: fileList[0].type,
                base64: e
            } as AvatarUploadModel;

            ApiService.api.updateAvatar(uploadModel).then((e: any) => {
                applicationUserStore.dispatch(CrudAction.Get, {});
            }).catch((e: any) => {
                throw e;
            });

        });
        
    }

    uploadAvatarClick (e: any) {
        e.preventDefault();
        this.uploadAvatarInput.click();
    }

    save () {
        if (this.form.validate()) {
            applicationUserStore.dispatch(CrudAction.Update, { item: this.user });
        }
    }

    savePasswordChange () {
        if (this.password != this.passwordAgain)
            this.invalidPasswordAgain = true;
        if (this.formPasswordChange.validate() && !this.invalidPasswordAgain) {
            this.savingPasswordChange = true;
            const model = {
                currentPassword: this.oldPassword,
                newPassword: this.password
            } as SetPasswordModel;
            ApiService.api.setPassword(model).then((e: any) => {
                this.savedPasswordChange = e.data.succeeded;
                if (!e.data.succeeded)
                    this.errorPasswordChange = true;
                this.savingPasswordChange = false;
            }).catch((reason: any) => {
                this.savingPasswordChange = false;
                throw reason;
            });
        }
    }

    loadInvitations() {
        tenantConnectionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "isActive", op: "eq", comparand: true }
        ]));
    }

    deleteInvitation (item: TenantInvitationModel) {
        this.deletingInvitation = item.id!;
        var payload = createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: item.id!}
        ]).query.data as any;
        ApiService.api.deleteTenantClientInvitations(payload).then(() => {
            this.loadInvitations();
            this.deletingInvitation = null;
        }).catch(() => {
            this.deletingInvitation = null;
        });
        /*tenantInvitationsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: item.id!}
        ]));*/
    }

    acceptInvitation (item: TenantInvitationModel) { 
        this.acceptingInvitation = item.id!;
        ApiService.api.insertTenantConnection(item).then(() => {
            this.loadInvitations();
            this.acceptingInvitation = null;
            window.location.reload();
        }).catch(() => {
            this.acceptingInvitation = null;
        });
    }

    deleteOrganizationClick (item: TenantModel) { 
        this.deleteOrganizationItem = item;
        this.deleteOrganizationDialog = true;
    }

    deleteOrganization () {
        tenantConnectionsStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [
            { field: "tenantId", op: "eq", comparand: this.deleteOrganizationItem!.id! }
        ]));
        this.deletingOrganization = true;
        this.subscribe(tenantConnectionsStore, CrudReponse.Delete).then(() => {
            this.deletingOrganization = false;
            window.location.reload();
        }).catch(() => {
            this.deletingOrganization = false;
        });
    }

    activateTwoFactor() {
        if (this.user.twoFactorEnabled) {
            this.twoFactorDialogType = "deactivate";
        }
        else {
            this.twoFactorDialogType = "activate";
        }
        this.twoFactorDialog = true;
    }

    reload() {
        applicationUserStore.dispatch(CrudAction.Get, {});
    }

}
