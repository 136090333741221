














































































































































































































































































































































import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createAggregationQueryPayload } from '@/libs/core/+state/models/aggregation-query-payload';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Constants } from '@/constants';
import { ApplicationUserModel, PlanItemModel, TenantUserModel } from '@/libs/Api';
import { tenantUsersStore } from '@/libs/tenants/+state/store';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';

moment.locale("cs");

@Component({
    components: {
    }
})
export default class DashboardDonePlanItems extends Page {

    today = new Date();
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail);
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    itemsPerPage = 10;
    showScore = false;

    get headers() {
        let headers = [
            { text: this.$i18n.t("planItems.time"), value: "time", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.audit.name"), value: "audit.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.evidenceNumber"), value: "evidenceNumber", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.category.name"), value: "audit.category.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.user.displayName"), value: "tenantUser.user.displayName", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.workplace.name"), value: "workplace.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.shiftType.name"), value: "shiftType.name", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.responseCount"), value: "responseCount", align: "left", width: "5%", sortable: false }
        ];

        if (this.showScore)
            headers.push({ text: this.$i18n.t("planItems.okCount"), value: "okCount", align: "left", width: "5%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("planItems.okCountWithWarning"), value: "nokCount", align: "left", width: "5%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("planItems.nokCount"), value: "nokCount", align: "left", width: "5%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 0", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 1", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 2", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 3", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 4", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 5", value: "nokCount", align: "left", width: "4%", sortable: false });

        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 6", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if  (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 8", value: "nokCount", align: "left", width: "4%", sortable: false });
        
        if (this.showScore)
            headers.push({ text: this.$i18n.t("common.scoreShort") + " 10", value: "nokCount", align: "left", width: "4%", sortable: false });

        headers.push(
            { text: this.$i18n.t("planItems.scoreValue"), value: "scoreValue", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.notAnswered"), value: "notAnsweredCount", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.successRate"), value: "nokCount", width: "5%", align: "left", sortable: false },
            { text: this.$i18n.t("planItems.planItemState"), value: "planItemState", align: "left", sortable: false },
            { text: "", value: "id", align: "left", sortable: false }
        );

        return headers;
    }

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.PendingBasic, "DonePlanItems");
    }
    
    get items(): PlanItemModel[] {
        return planItemsStore.useGetter(CrudGetter.DataBasic, "DonePlanItems");
    }

    @Watch("itemsPerPage")
    itemsPerPageChanged (v: number) {
        window.localStorage.amsItemsPerPage = v.toString();
    }

    created () {
        if (window.localStorage.showScore)
            this.showScore = JSON.parse(window.localStorage.showScore);
    }

    mounted () {
        this.itemsPerPage = window.localStorage.amsItemsPerPage ? Number(window.localStorage.amsItemsPerPage) : 10;
        this.loadItems();
    }

    loadItems () {
        planItemsStore.dispatch(CrudAction.GetAllBasic, createCrudQueryPayload<PlanItemModel>({ field: "time", index: 1, order: "desc" }, [
            { field: "time", op: "gte", comparand: moment().add(-1, "year").startOf("day").toDate() },
            { field: "time", op: "lte", comparand: moment().endOf("day").toDate() },
            { field: "isAnswered", op: "eq", comparand: true },
            { field: "tenantUserId", op: "eq", comparand: this.tenantUser.id! }
        ], undefined, false, "DonePlanItems"));
    }

    computeSuccessRate(responseCount: number, okCount: number): string {
		if (responseCount == 0)
            return "100";
        return (okCount / responseCount * 100).toFixed(2);
    }

    computeScoreSuccessRate(scoreValue: number, scoreMaxValue: number): string {
        if (scoreValue == null || scoreMaxValue == null)
            return "--";
        if (scoreMaxValue == 0)
            return "100 %";
        return (scoreValue / scoreMaxValue * 100).toFixed(2) + " %";
    }

    setShowScore () {
        this.showScore = !this.showScore;
        window.localStorage.showScore = this.showScore.toString();
    }

}

