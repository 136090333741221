














































































import Page from '@/Page.vue';
import {Component, Prop} from 'vue-property-decorator';
import { AiServiceQueueActionEnum, CategoryModel, PlanItemModel, QueryOperator, TenantUserModel } from '@/libs/Api';
import WorkTaskMeasureItem from '../components/WorkTaskMeasureItem.vue';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {planItemsStore} from '@/libs/plan-items/+state/store';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {aiServiceQueueItemsStore} from "@/libs/ai-service-queue-items/+state/store";
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from "@/libs/core/+state/models/crud-query-payload";
import { categoriesStore } from '@/libs/categories/+state/store';

@Component({
    components: {
        WorkTaskMeasureItem
    }
})
export default class WorkTaskReportTab extends Page {

    /* Page props */
    @Prop() id!: string;
    @Prop() tenantId!: string;
    
    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    /* Component state variables */
    isLoading = false;
    category: CategoryModel | null = null;

    /* Getters */

    get planItem(): PlanItemModel {
      return planItemsStore.useGetter(CrudGetter.Detail, this.id);
    }

    get isRunningAi(): boolean {
      return ([...aiServiceQueueItemsStore.useGetter(CrudGetter.Data, this.id)] ?? []).length > 0;
    }

    mounted() {
        const planItem = planItemsStore.useGetter(CrudGetter.Detail, this.id) as PlanItemModel;
        if (planItem && !planItem?.aiIsReportGenerated && planItem?.audit?.category?.aiUseReportGeneration == true) {
          aiServiceQueueItemsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
              { field: 'planItemId', op: QueryOperator.Eq, comparand: this.id },
              { field: 'action', op: QueryOperator.Eq, comparand: AiServiceQueueActionEnum.AiGeneratePlanItemReport },
              { field: 'isDone', op: QueryOperator.Ne, comparand: true }
          ], { pageIndex: 1, pageSize: 1 }, false, this.id));
        }
        this.loadCategory(planItem.audit!.categoryId!);
    }

    loadCategory(categoryId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            categoriesStore.dispatch(CrudAction.Get, { id: categoryId });
            this.subscribe(categoriesStore, CrudReponse.Get).then((e: CategoryModel) => {
                this.category = e;
                resolve(e);
            }).catch((e: any) => {
                reject(e);
            });
        });
    }

}
