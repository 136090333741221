
















































import Page from '@/Page.vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Settings extends Page {

    @Prop() tenantId!: string;

}
