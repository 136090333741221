














import {AuditModel, PlanItemModel} from '@/libs/Api';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import moment from 'moment';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import {auditsStore} from '@/libs/audits/+state/store';

@Component({})
export default class Calendar extends Page {

    @Prop() tenantId!: string;
    @Prop() auditId!: string;
    colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'];
	audit = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;

    get loading(): boolean {
        return planItemsStore.useGetter(CrudGetter.Pending);
    }

    get events(): Array<any> {
        const planItems = [...planItemsStore.useGetter(CrudGetter.Data) as PlanItemModel[]];
        return planItems.map((e) => {
            return {
                name: e.tenantUser?.user?.displayName,
                start: moment(e.time).toDate(),
                end: moment(e.time).toDate(),
                timed: false,
                color: "#778da9"
            };
        });
    }

    mounted () {
        this.load();
    }

    @Watch("auditId")
    auditIdChanged () {
        this.load();
    }

    load () {
        planItemsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<PlanItemModel>(undefined, [
            { field: "auditId", op: "eq", comparand: this.auditId }
        ]));
    }

}
