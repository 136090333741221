














































































































import ApiService from "@/libs/core/api-service"
import {AvatarUploadModel, TenantModel} from '@/libs/Api';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {tenantsStore} from '@/libs/tenants/+state/store';
import Page from '@/Page.vue';
import {Component, Prop, Ref} from 'vue-property-decorator';

@Component({})
export default class Organization extends Page {

    valid = false;
    uploadedFiles: any[] = [];
    uploadError: any = null
    currentStatus: any = null;
    uploadFieldName = 'photos';
	tenant: TenantModel | null = null;
    maxResolutionRules = [
        (v: any) => v === null || v === '' || v >= 100 || this.$i18n.t('common.mustBeMoreOrEqual', { value: 100 }),
        (v: any) => v === null || v === '' || Number.isInteger(v) || this.$i18n.t('common.enterAnInteger')
    ];
    uploadQualityRules = [
        (v: any) => v === null || v === '' || v >= 10 || this.$i18n.t('common.mustBeMoreOrEqual', { value: 10 }),
        (v: any) => v === null || v === '' || v <= 100 || this.$i18n.t('common.mustBeLessOrEqual', { value: 100 }),
        (v: any) => v === null || v === '' || Number.isInteger(v) || this.$i18n.t('common.enterAnInteger')
    ];
    maxFileSizeRules = [
        (v: any) => v === null || v === '' || v >= 1 || this.$i18n.t('common.mustBeMoreOrEqual', { value: 1 }),
        (v: any) => v === null || v === '' || Number.isInteger(v) || this.$i18n.t('common.enterAnInteger')
    ];

    @Prop() tenantId!: string;
    @Ref() uploadAvatarInput!: any;

    get saving(): boolean {
        return tenantsStore.useGetter(CrudGetter.Saving, this.tenantId);
    }

    mounted () {
        tenantsStore.dispatch(CrudAction.Get, { id: this.tenantId, key: this.tenantId });
		this.subscribe(tenantsStore, CrudReponse.Get, this.tenantId).then((e: TenantModel) => {
			this.tenant = {...e};
		});
    }

    save () {
		if (this.tenant!.imageUploadUseCompression != true) {
			this.tenant!.imageUploadMaxFileSize = null;
			this.tenant!.imageUploadMaxResolution = null;
			this.tenant!.imageUploadQuality = null;
		}
        tenantsStore.dispatch(CrudAction.Update, { item: this.tenant, key: this.tenantId });
        this.subscribe(tenantsStore, CrudReponse.Update, this.tenantId).then(() => {
            tenantsStore.dispatch(CrudAction.Get, { id: this.tenantId });
        });
    }

    getFileBase64(file: File, callback: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result!.toString().split(',')[1]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    filesChange (fieldName: string, fileList: FileList) {

        if (!fileList.length) return;

        this.getFileBase64(fileList[0], (e: string) => {

            const uploadModel = {
                contentType: fileList[0].type,
                base64: e
            } as AvatarUploadModel;

            ApiService.api.updateTenantAvatar(this.tenantId, uploadModel).then((e: any) => {
                tenantsStore.dispatch(CrudAction.Get, { id: this.tenantId, key: this.tenantId });
            }).catch((e: any) => {
                throw e;
            });

        });
        
    }

    uploadAvatarClick (e: any) {
        e.preventDefault();
        this.uploadAvatarInput.click();
    }

}
