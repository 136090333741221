























































































import Page from '@/Page.vue';
import { Component, Ref, VModel, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import { ApplicationUserModel, PlanItemModel, PlanItemStateEnum, TenantUserModel } from '@/libs/Api';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Guid } from '@/libs/common/functions/guid';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import ShiftTypeSelect from '@/libs/common/components-smart/ShiftTypeSelect.vue';
import CustomerSelect from '@/libs/common/components-smart/CustomerSelect.vue';

@Component({
    components: {
        CustomerSelect,
        ShiftTypeSelect,
    },
})
export default class WorkTaskCloseDialog extends Page {
    @VModel() dialog!: boolean;

    /* Component state variables */
    valid = true;
    saving = false;
    hasChanges = true;
    createPlanItem = false;
    isPeriod = false;
    item: PlanItemModel | null = null;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;

    @Watch('dialog')
    dialogChanged(v: boolean) {
        if (v) {
            const planItem = planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel;
            this.createPlanItem = false;
            this.isPeriod = false;
            this.item = {
                id: Guid.EmptyGuid(),
                auditId: planItem.auditId,
                audit: planItem.audit,
                tenantUserId: this.tenantUser.id,
                time: undefined,
                workplaceId: planItem.workplaceId,
                shiftTypeId: planItem.shiftTypeId,
                planItemState: PlanItemStateEnum.WaitingToPerform,
            } as PlanItemModel;
        }
    }

    mounted() {
        const planItem = planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel;
        this.createPlanItem = false;
        this.isPeriod = false;
        this.item = {
            id: Guid.EmptyGuid(),
            auditId: planItem.auditId,
            audit: planItem.audit,
            tenantUserId: this.tenantUser.id,
            time: undefined,
            workplaceId: planItem.workplaceId,
            shiftTypeId: planItem.shiftTypeId,
            planItemState: PlanItemStateEnum.WaitingToPerform,
        } as PlanItemModel;
    }

    @Ref() form!: any;

    /* Action functions */

    closeDialog() {
        this.$emit('input', false);
        this.dialog = false;
    }

    closePlanItem() {
        if (!this.createPlanItem || this.form.validate()) {
            this.saving = true;
            const planItem = { ...(planItemsStore.useGetter(CrudGetter.Detail) as PlanItemModel) };
            planItem.planItemState = PlanItemStateEnum.Closed;
            planItemsStore.dispatch(CrudAction.Update, { item: planItem });
            this.subscribe(planItemsStore, CrudReponse.Update)
                .then(e => {
                    if (!this.createPlanItem) {
                        this.saving = false;
                        this.closeDialog();
                    } else {
                        const item = { ...this.item };
                        item.since = item.time;
                        if (!this.isPeriod) {
                            item.until = item.time;
                        }
                        planItemsStore.dispatch(CrudAction.Create, { item: item });
                        this.subscribe(planItemsStore, CrudReponse.Create)
                            .then((e: PlanItemModel) => {
                                this.saving = false;
                                this.closeDialog();
                            })
                            .catch((e: any) => {
                                this.saving = false;
                            });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
}
