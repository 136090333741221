



























































import { FileModel } from '@/libs/Api';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import Page from '@/Page.vue';
import axios from 'axios';
import { getAccessToken } from 'axios-jwt';
import { Component, Prop, Ref, VModel, Watch } from 'vue-property-decorator';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

const b64toBlob = (b64Data: string, contentType='', sliceSize=512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

@Component({
    components: {
        VuePdfEmbed
    }
})
export default class QuestionFileDetail extends Page {

    dialog = false;
    deleteDialog = false;
    // file: FileModel | null = null;
    blobUrl = "";
    zoomed = false;
    loading = false;
    downloading = false;

    @VModel() file!: any;
    @Prop({ default: false }) canPrev!: boolean;
    @Prop({ default: false }) canNext!: boolean;
    @Prop({ default: false }) canEdit!: boolean;
    @Prop({ default: true }) persistent!: boolean;
    
    get isImage(): boolean {
        const imageTypes = [ 'image/jpeg', 'image/png', 'image/svg+xml', 'image/heic' ];
        return imageTypes.any(n => n == this.file?.file?.file?.contentType);
    }
    
    get isPdf(): boolean {
        const pdfTypes = [ 'application/pdf' ];
        return pdfTypes.any(n => n == this.file?.file?.file?.contentType);
    }
    
    @Watch("dialog")
    dialogChanged(v: boolean) {
        if (!v)
            this.close();
    }

    @Watch("file", { immediate: true, deep: true })
    fileChanged() {
        if (this.file)
            this.getFile(this.file);
    }

    mounted() {
        if (this.file)
            this.getFile(this.file);
    }

    public open (file: any) {
        this.file = file;
        this.zoomed = false;
        this.dialog = true;
    }

    getFile(file: any) {
		const targetFile = file.file;
        if (targetFile) {
            this.loading = true;
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            this.downloading = true;
            axios({
                url: (window as any).ApiService.baseUrl + "/api/files/" + targetFile.fileId! + "/download",
                method: 'GET',
                responseType: 'blob', // important,
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((response) => {
                this.loading = false;
                const url = window.URL.createObjectURL(new Blob([response.data], { type: file.file.file.contentType }));
                this.blobUrl = url;
                this.downloading = false;
            }).catch((e: any) => {
                this.loading = false;
                this.downloading = false;
                return "";
            });
        }
    }
    
    downloadFile(file: any) {
        const targetFile = file.file;
        if (targetFile) {
            this.loading = true;
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            this.downloading = true;
            axios({
                url: (window as any).ApiService.baseUrl + "/api/files/" + targetFile.fileId! + "/download",
                method: 'GET',
                responseType: 'blob', // important,
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((response) => {
                debugger;
                this.loading = false;
                const blob = response.data as any;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', file.file.file.fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
                this.downloading = false;
            }).catch((e: any) => {
                this.loading = false;
                this.downloading = false;
                return "";
            });
        }
    }

    close () {
        this.dialog = false;
    }

    prev () {
        this.$emit("prev");
    }

    next () {
        this.$emit("next");
    }

    deleteItemConfirm () {
        debugger;
        this.$emit("delete", this.file.file);
        this.deleteDialog = false;
        this.$nextTick(() => {
            this.close();
        });
    }
    
    
    
}
