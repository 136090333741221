import {Module} from 'vuex';
import store from '@/libs/core/+state/store';
import {isFunction} from 'lodash';

export interface IWorkTasksShowDetailsStore {
	dispatch (code: WorkTasksShowDetailTypeEnum, value: boolean): void;
	useGetter (code: WorkTasksShowDetailTypeEnum): boolean;
}

export enum WorkTasksShowDetailTypeEnum {
	ShowProductAuditDetails = 'showProductAuditDetails'
}

export class WorkTasksShowDetails {
	showProductAuditDetails = false;
}

function getBoolLocalStorage(code: string, defaultValue = false) {
	if (window.localStorage.getItem(code) == null)
		return defaultValue;
	return window.localStorage.getItem(code) == "true";
}

const registerWorkTasksShowDetailsStore = (): IWorkTasksShowDetailsStore => {
	const storeObject: Module<WorkTasksShowDetails, unknown> = {
		namespaced: true,
		state: {
			showProductAuditDetails: getBoolLocalStorage('showProductAuditDetails')
		},
		actions: {
			['Set']: ({commit, state}, payload: { code: string, value: boolean }) => {
				window.localStorage.setItem(payload.code, payload.value.toString());
				commit('Set', payload);
			}
		},
		mutations: {
			['Set']: (state, payload: { code: string, value: boolean }) => {
				state[payload.code as keyof WorkTasksShowDetails] = payload.value;
			}
		},
		getters: {
			[WorkTasksShowDetailTypeEnum.ShowProductAuditDetails]: (state): boolean => {
				return state.showProductAuditDetails;
			}
		}
	};
	store.registerModule("WorkTasksShowDetails", storeObject);
	return {
		dispatch: (code: WorkTasksShowDetailTypeEnum, value: boolean) => {
			store.dispatch(`WorkTasksShowDetails/Set`, {code, value});
		},
		useGetter: (code: WorkTasksShowDetailTypeEnum) => {
			const val = store.getters[`WorkTasksShowDetails/${code}`];
			return isFunction(val) ? val() : val;
		}
	};
}

export const workTasksShowDetailsStore = registerWorkTasksShowDetailsStore();